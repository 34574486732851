<template>
  <div style="background-color: white;">
    <van-sticky>
      <van-nav-bar left-text="面试宝典"
                   right-text="分享此题"
                   fixed
                   placeholder
                   safe-area-inset-top
                   left-arrow
                   @click-left="onClickLeft"
                   @click-right="onClickRight">
        <template #title>
          <span style="font-weight:600;color:#0af">{{ questionType | questionTypeFilter }}</span>
        </template>
      </van-nav-bar>

      <van-cell size="large">

        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <span v-if="info.questionType=='1'">{{info.number}}.{{info.questionName}}</span>
          <span v-if="info.questionType!='1'">{{info.questionName}}</span>
        </template>

        <template #label>
          <van-row justify="space-around">
            <van-col span="3">
              <van-tag v-if="info.level==1"
                       color="#67c23a"
                       plain>简单</van-tag>
              <van-tag v-else-if="info.level==2"
                       color="#e6a23c"
                       plain>中等</van-tag>
              <van-tag v-else
                       color="#f56c6c"
                       plain>困难</van-tag>

            </van-col>
            <van-col span="7">
              <van-icon name="clock"
                        color="#ff5722" /> {{info.time}}
            </van-col>
            <van-col span="3">
              <van-icon name="fire"
                        color="#ee0a24" /> {{info.frequency}}
            </van-col>
          </van-row>
        </template>
      </van-cell>
    </van-sticky>
    <van-button v-if="questionType == 2"
                type="warning"
                size="small"
                :url="info.url"
                block>本题 LeetCode 传送门</van-button>
    <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
      试题解析
    </van-divider>
    <van-row v-if="info.videoUrl != null"
             type="flex"
             justify="center">
      <van-col span="22">
        <iframe :src="info.videoUrl"
                width="100%"
                height="200"
                scrolling="no"
                border="0"
                frameborder="no"
                framespacing="0"
                allowfullscreen="true"> </iframe>

      </van-col>
    </van-row>

    <van-row type="flex"
             justify="center">
      <van-col span="22">
        <div v-if="info.questionDetail != null"
             class="markdown-body">
          <!-- <vue-markdown :source="info.questionDetail"></vue-markdown> -->

          <viewer ref="markdownViewer"
                  :options="viererOptions"
                  :height="'auto'"
                  :initialValue="info.questionDetail"></viewer>

        </div>
        <div v-else>
          <van-empty description="解析整理中" />
        </div>
      </van-col>
    </van-row>

    <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
      参与讨论
    </van-divider>
    <van-row type="flex"
             justify="center">
      <van-col span="22">
        <Valine :options="valineOptions" />
      </van-col>
    </van-row>
    <van-share-sheet v-model="showShare"
                     title="立即分享"
                     :options="shareOptions"
                     @select="onSelect" />

  </div>
</template>

<script>
import { ImagePreview, Sticky, Button, Dialog, Divider, Collapse, CollapseItem, ShareSheet, Empty, NavBar, Toast, Search, DropdownMenu, DropdownItem, List, Cell, CellGroup, Tag, Icon, Col, Row, Switch } from 'vant';
import Valine from '../Valine.vue';
const axios = require("axios");

import 'prismjs/themes/prism.css';
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
import Prism from 'prismjs';
import 'prismjs/components/prism-go.js';
// import './style/toastui-editor.css';
import "@toast-ui/editor/dist/i18n/zh-cn";
import '../css/toastui-editor-viewer.css';
import { Viewer } from '@toast-ui/vue-editor';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight';

export default {
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
    [Sticky.name]: Sticky,
    viewer: Viewer,
    [Button.name]: Button,
    [Dialog.Component.name]: Dialog.Component,
    [Divider.name]: Divider,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [ShareSheet.name]: ShareSheet,
    Valine,
    [Empty.name]: Empty,
    [NavBar.name]: NavBar,
    [Toast.name]: Toast,
    [Search.name]: Search,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [List.name]: List,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Tag.name]: Tag,
    [Icon.name]: Icon,
    [Col.name]: Col,
    [Row.name]: Row,
    [Switch.name]: Switch
  },

  data () {
    return {
      viererOptions: {
        plugins: [[codeSyntaxHighlight, { highlighter: Prism }]]
      },
      id: '',
      info: {},
      questionType: '',
      showShare: false,
      shareOptions: [
        { name: '微信', icon: 'wechat' },
        { name: '分享海报', icon: 'https://img01.yzcdn.cn/vant/custom-icon-light.png' },
      ],
      valineOptions: {
        appId: 'kWKlQCNOVzR8JT7EAPJXvanE-gzGzoHsz',
        appKey: 'nscorEe7TaRyn1DBFNicKsWH',
        placeholder: '欢迎大家留言交流，填写邮箱可接收回复信息哦！😎 支持markdown格式，点击右下角预览查看效果。',
        path: window.location.pathname,
      },

    };
  },
  filters: {
    questionTypeFilter (val) {
      switch (val) {
        case '1':
          return "GOLANG";
        case '2':
          return "数据结构与算法";
        case '3':
          return "数据库";
        case '4':
          return "操作系统";
        case '5':
          return "网络";
        case '6':
          return "系统设计";
        case '7':
          return "工具";
        case '8':
          return "通用能力";
        default:
          return "11GOLANG";
      }
    },

  },
  created () {
    this.questionType = this.$route.query.questionType
    this.id = this.$route.query.id
     //设置邀请码
     if(this.$route.query.code !== undefined){
      window.localStorage.setItem('code', this.$route.query.code)
    }
    this.initData()
  },
  methods: {

    initData () {
      axios
        .get("https://api.golangroadmap.com/question/m/detail?id=" + this.id)
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            this.info = response.data.data
            this.valineOptions.path = '/#/detail?item=0&questionType=' + this.questionType + '&id=' + this.info.id
            console.log(this.info.url)
          } else {
            console.log(response.data.msg);
            Toast(response.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    //分享
    onSelect (shareOption) {
      if (shareOption.name == '分享海报') {

        var shareImage = 'https://image-1302243118.cos.ap-beijing.myqcloud.com/share/question_' + this.info.id + '.jpg'

        const img = new Image();
        img.src = shareImage;
        img.onload = function () {
          console.log("Image loaded successfully.");
          ImagePreview({
            images: [
              shareImage,
            ],
            closeable: true,
          });
          this.showShare = false;
          // Do something if the image is loaded successfully
        };
        img.onerror = function () {
          console.log("Failed to load image.");
          // Do something if the image fails to load
          Dialog({ message: '暂无海报,请选择微信分享', theme: 'round-button', confirmButtonColor: '#0af' });
        };
      } else {
        Dialog({ message: '本题链接已复制到剪切板，前往微信分享给朋友吧！', theme: 'round-button', confirmButtonColor: '#0af' });
        this.showShare = false;
        let container = this.$refs.container
        var invitationCode = window.localStorage.getItem('invitationCode') == null ? 'Go' : window.localStorage.getItem('invitationCode')
        var shareUrl = "分享个面试题给你 : https://m.golangroadmap.com/#/sharequestion?questionType=" + this.questionType + '&id=' + this.info.id + "&code=" + invitationCode + "。GOLANG ROADMAP一个专注Go语言学习、求职的社区。"
        this.$copyText(shareUrl, container)
      }

    },
    onClickLeft () {
      this.$router.push({ path: '/home', query: { tab: '4' } });
    },
    onClickRight () {
      this.showShare = true
    },


  }
};
</script>

<style lang="less">
/* iphone plus 414px */
@media screen and (max-width: 414px) and (min-width: 361px) {
  .van-cell__label {
    max-width: 384px;
  }
}
/* iphone 320px */
@media screen and (max-width: 320px) {
  .van-cell__label {
    max-width: 290px;
  }
}

/* android 360px*/
@media screen and (max-width: 360px) and (min-width: 321px) {
  .van-cell__label {
    max-width: 330px;
  }
}
/* android 360px*/
@media screen and (max-width: 375px) and (min-width: 361px) {
  .van-cell__label {
    max-width: 345px;
  }
}

@media screen and (max-width: 480px) and (min-width: 415px) {
  .van-cell__label {
    max-width: 450px;
  }
}

@media screen and (max-width: 540px) and (min-width: 481px) {
  .van-cell__label {
    max-width: 510px;
  }
}
</style>
